//import necessary files for PricingComparison component
import React from "react";
import "./PricingComparison.scss";
import images from "../../constants";
//import react icon hi library for green checkmark
import { HiCheck, HiX } from "react-icons/hi";
//import calculateVoipPlanPrice function from utils folder
const PricingComparisonTenderBriefAttend = () => {
  return (
    //Pricing comparison component
    <div className="container py-3">
      <main>
        <h2 className="display-3 text-center mb-4">
          Why Choose <strong>TenderBriefAttend&trade;</strong> Over Travelling
          To The Tender Briefing Yourself?
        </h2>
        <div className="text-center lead">
          Avoid expensive flights and travel expenses! For example, a round trip
          from Cape Town to Pretoria could cost R5,200 or more with local
          airline travel.
        </div>
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th className="w-34">Our Package</th>
                <th className="w-22">Number of Attendances/Month</th>
                <th className="w-22">Total Client Cost (R)</th>
                <th className="w-22">Self-Attendance Cost (R)</th>
                <th className="w-22">Client Savings (R)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Ad-Hoc
                </th>
                <td>1</td>
                <td>R 1,280</td>
                <td>R 5,200</td>
                <td>R 3,920</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Standard
                </th>
                <td>5</td>
                <td>R 6,399.75</td>
                <td>R 26,000</td>
                <td>R 19,600.25</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Premium
                </th>
                <td>10</td>
                <td>R 12,799.50</td>
                <td>R 52,000</td>
                <td>R 39,200.50</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th className="w-34"></th>
                <th className="w-22">TenderBriefAttend&trade;</th>
                <th className="w-22">In-Person Attendance (Your Staff)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Travel & Logistics (Book flights & accommodation etc)
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Cost Efficiency
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                  - Typically more cost-effective than in-house travel - Pay
                  only for what you use per briefing
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                  - High cost for flight, lodging, daily expenses - Unused staff
                  downtime while traveling
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Saves Time
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Coverage & Scalability -handle many briefings same time
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Accuracy & Reliability
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Data & Documentation
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Fast To Attend Briefing
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Support & Accountability
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row" className="text-start">
                  Outcome
                </th>
                <td>
                  <strong>TenderBriefAttend&trade;</strong>, provides an
                  end-to-end solution that removes the need for your staff to
                  travel, ensures proper sign-in for compliance, and delivers
                  briefing documentation quickly and reliably.
                </td>
                <td>
                  <strong>In-Person Attendance</strong>, offers maximum control
                  but can be expensive and time-consuming. Unused staff downtime
                  while they are traveling.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};

export default PricingComparisonTenderBriefAttend;
