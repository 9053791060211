import React from "react";
//import the css file for styling
import "./TolaTenderPro.scss";

//import assets from constants
import assets from "../../constants";

//import cookies hook from react cookies library
import { useCookies } from "react-cookie";

// Create a style object and set the `backgroundImage` property to the URL of the background image variable.
const styles = {
  backgroundImage: `url(${assets.bgpofinance1})`,
  opacity: 1,
  backgroundPosition: `top left`,
  bacgroundSize: "cover",
};
//create TolaTenderProle
const TolaTenderPro = (props) => {
  const [cookies] = useCookies(["affid", "urlParams"]);
  //let affilliateID = cookies.affid;
  // if (cookies.affid != null) {
  //   affilliateID = cookies.affid;
  //   //log in a console the value of the cookie
  //   //console.log("Cookie set to:" + affilliateID);
  // } else {
  //   //log in a console that the cookie is null
  //   //console.log("cookie is null");
  // }

  const $utm_source = cookies.urlParams.utm_source || "";
  const $utm_medium = cookies.urlParams.utm_medium || "";
  const $utm_campaign = cookies.urlParams.utm_campaign || "";
  const $utm_term = cookies.urlParams.utm_term || "";
  const $utm_content = cookies.urlParams.utm_content || "";
  const affid = cookies.affid || "";

  //Handle when user click Buy Button and redirect the user to a new url
  const handleRFQButton = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page

    const rfqUrl = "https://marketdirect.zohobookings.com/#/tenderpro?";
    window.open(rfqUrl, "_blank");
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleLearnMore = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    //const rfqUrl ="https://forms.zoho.com/virtualoffice12496/form/MarketDirectcozaPurchaseOrderFundingApplication/formperma/KNHMcBROaHBa0Z8wkGlIi4nGxGybgEGFkbR7CPKs5Is?";
    const rfqUrl =
      "https://blog.marketdirect.co.za/win-tenders-by-maximising-your-bee-functionality-score-in-south-africa/?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleGetQuote = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    //const rfqUrl ="https://forms.zoho.com/virtualoffice12496/form/MarketDirectcozaPurchaseOrderFundingApplication/formperma/KNHMcBROaHBa0Z8wkGlIi4nGxGybgEGFkbR7CPKs5Is?";
    const rfqUrl =
      "https://applications.marketdirect.co.za/virtualoffice12496/form/TolaTenderProCustomerOrderForm/formperma/rfQvQMiBim-scf3o9i-nyHcKWWf0X5gear6tvWoPins?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Chat Button and redirect the user to a new url
  const handleChat = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl = "https://api.whatsapp.com/message/EF2LXKA73QPGJ1";
    window.open(rfqUrl, "_blank");
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleNoCreditChecks = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl =
      "https://marketdirect-blog.vercel.app/posts/get-cheaper-bank-loans-in-future-with-marketdirect-co-za-tender-finance?";

    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  return (
    //TolaTenderPro section and content
    <section id="TolaTenderPro">
      <div>
        <div className="row pt-5 align-items-right">
          <div className="col-sm p-3 order-1 align-self-start">
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={assets.TolaTenderProMaMkhize}
                    className="d-block w-100 rounded img-fluid"
                    alt="Get TolaTenderPro - We Do Tender Search, Bid Preparation and Submission For You!"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.TolaTenderProWinningWoman}
                    className="d-block w-100 rounded img-fluid"
                    alt="Get TolaTenderPro - Maximimize Your Tender B-BBEE Score, Bid Preparation and Submission For You!"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.TolaTenderProWinningMan}
                    className="d-block w-100 rounded img-fluid"
                    alt="Get TolaTenderPro - Maximimize Your Tender B-BBEE Score, Bid Preparation and Submission For You!"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm p-3 order-5 align-self-start">
            <h1 className="display-4 text-white text-left fw-bolder">
              {`Get TolaTenderPro - Maximize Your Tender Bid Score & Win More Contracts ${props.city}`}
            </h1>
            <p className="lead text-white text-left">
              <h3>
                Did you know that tenders in South Africa are open to businesses
                of all backgrounds, including white-owned companies?
              </h3>
              Winning tenders isn’t just about submitting the lowest price—it’s
              about maximizing your tender bid score to improve your{" "}
              <strong>Preferential Procurement Points</strong>. Many businesses
              miss out on tenders not because they lack capacity, but because
              their B-BBEE and functionality scores are too low.
              <br />
              <br />
              At <strong>TolaTenderPro&trade;</strong>, we specialize in{" "}
              <strong>Tender Bid Score Improvement</strong>, helping you
              strategically boost your compliance level and increase your tender
              success rate.
              <br />
              <br />
              <h2>
                How TolaTenderPro&trade; Enhances Your Tender Bid Score and
                Tender Bid Success
              </h2>
              {/* <strong>
                Plus, we have something new for you! Gain immediate access to
                easier to get subcontracting opportunities from businesses that
                have already secured RFQs and tenders when you buy TolaTenderPro
                service.
              </strong>
              <br />
              <br /> */}
              <ul>
                <li>
                  <strong>Tender Bid Score Improvement:</strong> Our experts
                  analyze your current B-BBEE and functionality scores and
                  implement tailored strategies to enhance your rating, such as
                  facilitating partnerships, advising on ownership structures,
                  and optimizing procurement practices.
                </li>
                <li>
                  <strong>Tender Search and Bid Preparation:</strong> We
                  identify tenders that align with your business and assist in
                  preparing compelling bids that highlight your strengths and
                  compliance.
                </li>
                <li>
                  <strong>Joint Venture Facilitation:</strong> Forming compliant
                  Joint Ventures (JVs) as when needed, with the right partners
                  can boost your functionality and B-BBEE scores, making your
                  bids more competitive.
                </li>
                {/* <li>
                  <strong>Get subcontracting opportunities:</strong> We connect
                  you with subcontracting opportunities from businesses that
                  have already secured tenders, allowing you to participate in
                  larger projects and gain valuable experience.
                </li> */}
                <li>
                  <strong>Expert advise on pricing strategies:</strong> In
                  construction, for instance, we make use of Cost Consultants to
                  help you submit competitive RFQ and tender bids.
                </li>
                <li>
                  <strong>Get no-credit check funding:</strong> We can help you
                  get our no-credit checks funding too, once you've been awarded
                  a contract.
                </li>
              </ul>
              <strong>
                Don't let a low tender bid core hinder your business growth.
                Partner with <strong>TolaTenderPro&trade;</strong> to enhance
                your tender bid score and increase your tender bid success rate.
                Get your quote and a FREE 30-minute consultation today and
                unlock your business's full potential, get a quote now, or learn
                more - press a blue button below!
              </strong>
            </p>
            <div className="pb-10 text-center">
              <a
                className="w-50 btn btn-lg btn-primary"
                onClick={handleGetQuote}
              >
                GET A QUOTE AND PRICING
              </a>
            </div>
            <div className="p-10 text-center">OR</div>
            <div className="d-flex flex-column">
              {/* <div className="p-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleRFQButton}
                >
                  BOOK A FREE CONSULTATION
                </a>
              </div>
              <div className="p-10 text-center">OR</div> */}
              <div className="pb-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleLearnMore}
                >
                  LEARN MORE
                </a>
              </div>
              {/* <div className="p-10 text-center">OR</div>
              <div className="pb-10 text-center">
                <a className="w-50 btn btn-lg btn-primary" onClick={handleChat}>
                  CHAT WITH AN EXPERT NOW!
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TolaTenderPro;
